@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
* {
  touch-action: manipulation;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', 'Arial',sans-serif;
}
